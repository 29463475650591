import React from 'react';
import logo from '../../logo_blanc.png';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

const DivHeader = styled.div`
  height: 50px;
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: rgba(0,0,0,0.15);

`;

const AppTitle = styled.h1`
  color: white;
  font-size: 20px;
  margin: 0;
  text-align: left !important;
`;

const AppDescription = styled.p`
  color: white;
  margin: 0;
`;

const Logo = styled.img`
  width: unset !important;
  height: 75%;
  margin: 1% 2%;
`;

function Header({monument}) {
  let history = useHistory();
  return (
    <DivHeader onClick={() => history.push(`/`)}>
      <Logo src={logo} alt="logo" />
      <div>
        <AppTitle>{monument.name}</AppTitle>
        <AppDescription>{monument.description}</AppDescription>
      </div>
    </DivHeader>
  )
}

export default Header;
