import React from 'react';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';


const FormikTextField = (props) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      {...field}
      {...props}
      value={field.value === null ? '' : field.value}
      style={{ width: '100%', marginTop: '8px', ...props.style }}
      error={meta.touched && meta.error != null}
      helperText={meta.touched && meta.error}
    />
  );
};

export default FormikTextField;
