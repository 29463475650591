import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getMonument, recordSession, move, remove, restore } from "../redux/appSlice";
import styled from "styled-components";
import ReactGA from "react-ga";
import BottomContainer from "../components/BottomContainer";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { LppTrashIcon, LppRestoreFromTrashIcon, LppMapIcon } from "../components/lppIcons";
import { Map } from "@material-ui/icons";
import LPPMap from "../components/LPPMap";
import FullScreenPlan from "../components/FullScreenPlan";

const Monument = styled.div`
  height: 40vw;
  max-height: 25vh;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 center;
  margin-top: 1vh !important;
  background-image: ${(props) => `url("${props.server}/images/${props.id}_0.png")`};
  position: relative;
  z-index: 1;
  display: flex;
  &:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: ${(props) => (props.isDeleted ? "rgba(255,255,255,.70)" : "rgba(50,50,50,.35)")};
  }
  & > * {
    color: white;
    margin: auto 25px;
  }
`;

const LPPLink = styled(Link)`
  text-decoration: none;
`;

const BottomMap = styled.div`
  position: fixed;
  width: 100% !important;
  bottom: 0;
  display: flex;
  z-index: 25;
`;

const MapButton = styled.div`
  width: 500px;
  max-width: 100%;
  margin: auto;
  position: relative;
  height: 65px;
`;

const Inside = styled.div``;

const Title = styled.h1`
  margin: 0 0 2px;
  font-weight: 400;
  font-size: 20px !important;
`;

const Description = styled.p`
  margin: 0 0 2px;
`;

const Infos = styled.div``;

const Duration = styled.div``;

const Rating = styled.div``;

function List() {
  let { monumentID } = useParams();
  const { monument, server } = useSelector((state) => state.appSlice);
  const [isReorder, setReorder] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [map, setMap] = useState(false);
  const dispatch = useDispatch();
  const hasMap = monument?.children?.find((child) => child.longitude && child.longitude != 0);

  const hasMapId = monument.hasmap
    ? monument.monument_id
    : monument.showmap
      ? monument.parent_id || monument.monument_id
      : null;

  useEffect(() => {
    dispatch(getMonument(monumentID));
    dispatch(recordSession(monumentID, true));
  }, [monumentID]);

  useEffect(() => {
    if (monument && monument.name) {
      ReactGA.pageview("/" + monument.monument_id + "-" + monument.name);
    }
  }, [monument]);

  const toggleMap = () => {
    setShowPlan(!showPlan);
  };

  const SortableItem = SortableElement(({ value }) => <div>{value}</div>);

  const SortableList = SortableContainer(({ items }) => (
    <ul style={{ listStyleType: "none", padding: "0" }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);
    dispatch(move(monument, oldIndex, newIndex));
  };

  const restoreChild = (e, child) => {
    e.preventDefault();
    dispatch(restore(monument, child.monument_id, child.child));
  };

  const removeChild = (e, child) => {
    e.preventDefault();
    dispatch(remove(monument, child.monument_id, child.child));
  };

  const setSorting = () => {
    setReorder(!isReorder);
  };

  let items;
  if (monument && monument.children) {
    items = monument.children
      .filter((e) => monument.isAuth || !e.is_deleted)
      .map((child) => (
        <LPPLink
          key={`link_${child.monument_id}`}
          to={child.isleaf ? `/slider/${monument.monument_id}/${child.monument_id}` : `/list/${child.monument_id}`}
        >
          <Monument
            key={`monument_${child.monument_id}`}
            isDeleted={child.is_deleted}
            server={server}
            id={child.monument_id}
          >
            <Inside>
              <Title>{child.name}</Title>
              <Description>{child.description}</Description>
              <Infos>
                <Duration></Duration>
                <Rating></Rating>
              </Infos>
              {!isReorder &&
                monument.isAuth &&
                (child.is_deleted ? (
                  <LppRestoreFromTrashIcon bottom={true} onClick={(e) => restoreChild(e, child)} />
                ) : (
                  <LppTrashIcon bottom={true} onClick={(e) => removeChild(e, child)} />
                ))}
            </Inside>
          </Monument>
        </LPPLink>
      ));
  }

  return (
    <>
      {monument && monument.hasOwnProperty("monument_id") && (
        <>
          {hasMapId && (
            <div style={{ position: "absolute", top: 0, right: 0, zIndex: 100, display: "inline-flex", width: "auto" }}>
              <LppMapIcon onClick={toggleMap} />
            </div>
          )}
          {map ? (
            <LPPMap monument={monument} />
          ) : isReorder ? (
            <SortableList
              lockAxis="y"
              helperClass="field_sorting"
              items={items}
              onSortEnd={onSortEnd}
              shouldCancelStart={(e) => e.target.matches(".nodrag, input, select, option, button")}
            />
          ) : (
            items
          )}
          {monument.isAuth && (
            <BottomContainer monument={monument} setSorting={setSorting} isSorting={isReorder} type="monument" />
          )}
          {hasMap && !monument.isAuth && (
            <BottomMap>
              <MapButton>
                <Map
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#01163b",
                    color: "white",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50px",
                    padding: "5px",
                  }}
                  onClick={() => {
                    setMap(!map);
                  }}
                />
              </MapButton>
            </BottomMap>
          )}
          {showPlan && <FullScreenPlan monumentId={hasMapId} />}
        </>
      )}
    </>
  );
}

export default List;
