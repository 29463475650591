import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs'
import ReactGA from 'react-ga';
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

ReactGA.initialize('UA-71934503-1');

// Manage network
const getServer = () => {
  const environment = process.env.REACT_APP_ENV
  console.log(environment)
  if (environment === "local") {
    return process.env.REACT_APP_RESSOURCES_HOST
  }
  //return "https://resources." + loadBalancer() + ".lespierresparlent.com"
  return "https://resources.backup.lespierresparlent.com"
}

const getWebservices = () => {
  const environment = process.env.REACT_APP_ENV
  console.log(environment)
  if (environment === "local") {
    return `${process.env.REACT_APP_SERVER_HOST}/v1`;
  }
  return "https://ws.lespierresparlent.com/v1";
}

function loadBalancer() {
  let max_servers = 3
  return Math.floor(Math.random() * Math.floor(max_servers) + 1);
}

// Manage dates
function now() {
  return Math.floor(Date.now() / 1000)
}

function formatTs(timestamp) {
  return dayjs.unix(timestamp).toISOString()
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    parentMonument: {},
    monument: {},
    translations: null,
    sessionId: null,
    server: getServer(),
    pageStarts: now(),
    webservices: getWebservices(),
    token: null,
  },
  reducers: {
    getParentMonumentAsync: (state, action) => {
      state.parentMonument = action.payload;
    },
    getMonumentAsync: (state, action) => {
      state.monument = action.payload;
    },
    setDetail: (state, action) => {
      state.monument.detail = action.payload;
    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
      state.monument = {};
    },
    recordSessionAsync: (state, action) => {
      state.sessionId = action.payload.stats_id
      state.pageStarts = action.payload.pageStarts || now()
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    }
  },
});

export const { getParentMonumentAsync, getMonumentAsync, recordSessionAsync, setToken, setDetail, setTranslations } = appSlice.actions;

export const getMonument = (monumentID, language, isParent = false) => async (dispatch, getState) => {
  const state = getState();
  if (monumentID === 'new') {
    dispatch(getMonumentAsync({ monument_id: "new", language: "FR", type: 1, parent_id: state.appSlice.monument.detail || state.appSlice.monument.monument_id }))
  } else {
    let monument = await axios.get(getWebservices() + "/monument", {
      params: {
        monument_id: monumentID,
        language,
        token: state.appSlice.token
      }
    });
    if (monument.data.translations) {
      dispatch(setTranslations(monument.data.translations))
    } else {
      if (state.appSlice.monument.monument_id !== 'new')
        monument.data.parent_id = state.appSlice.monument.monument_id
      dispatch((isParent ? getParentMonumentAsync : getMonumentAsync)(monument.data))
    }
  }
}

export const move = (monument, oldIndex, newIndex) => async (dispatch, getState) => {
  const state = getState();

  try {
    await axios.post(getWebservices() + "/monument/move", {
      monument_id: monument.monument_id,
      oldIndex,
      newIndex,
      token: state.appSlice.token
    })
    dispatch(getMonument(state.appSlice.monument.monument_id))
  } catch (e) {
    console.error(e);
    alert("Une erreur est survenue, merci de bien vouloir me contacter Antoine / 06 22 31 16 10")
  }
}

export const remove = (monument, childId, position) => async (dispatch, getState) => {
  const state = getState();

  await axios.post(getWebservices() + "/monument/remove", {
    monument_id: monument.monument_id,
    child_id: childId,
    position,
    token: state.appSlice.token
  })
  dispatch(getMonument(state.appSlice.monument.monument_id))
}

export const restore = (monument, childId, position) => async (dispatch, getState) => {
  const state = getState();

  await axios.post(getWebservices() + "/monument/restore", {
    monument_id: monument.monument_id,
    child_id: childId,
    position,
    token: state.appSlice.token
  })
  dispatch(getMonument(state.appSlice.monument.monument_id))
}

export const saveMonument = (monument) => async (dispatch, getState) => {
  const state = getState();
  try {
    await axios.post(getWebservices() + "/monument", {
      monument,
      token: state.appSlice.token
    })
    dispatch(getMonumentAsync({}))
    window.history.back();
  } catch (e) {
    console.error(e);
    alert("Une erreur est survenue, merci de bien vouloir me contacter Antoine / 06 22 31 16 10")
  }

}

export const addDefinition = (monumentId, definitionId) => async (dispatch, getState) => {
  const state = getState();
  await axios.post(getWebservices() + "/monument/addDefinition", {
    monumentId,
    definitionId,
    token: state.appSlice.token
  })
}

export const recordSession = (monumentID, forceRecord) => async (dispatch, getState) => {
  const state = getState();
  let start = state.appSlice.pageStarts
  let end = now()
  let diff = end - start;

  if (forceRecord || (diff >= 10 && diff <= (15 * 60))) {
    let sessionId = await record(monumentID, state, start, end)
    return dispatch(recordSessionAsync(sessionId, monumentID))
  }
  return dispatch(recordSessionAsync(state.appSlice.pageStarts))
}

async function record(monumentID, state, start, end) {

  return (
    await axios.post(getWebservices() + "/record-session", {
      monument_id: monumentID,
      detail_id: null,
      stats_id: state.appSlice.sessionId,
      t_start: formatTs(start),
      t_end: formatTs(end)
    })).data;
}

export async function searchDefinitions(query) {
  return (
    await axios.post(getWebservices() + "/monuments/search", {
      query
    })).data;
}


export default appSlice.reducer;
