import { useFormikContext } from 'formik';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

export default process.env.NODE_ENV == 'production' ? () => null : () => {
  const { errors, values, touched, initialValues } = useFormikContext();
  const data = { errors, values, touched, initialValues }

  const renderTree = (nodes, prefix = '') => {
    if (typeof nodes === 'object' && nodes !== null) {
      const entries = Object.entries(nodes)
      if (entries.length > 0) {
        return entries.map(([key, value]) => {
          if ((typeof value === 'object' && value !== null) || Array.isArray(value)) {
            return <TreeItem key={prefix+key} nodeId={prefix+key} label={key}>
              {renderTree(value, prefix+key)}
            </TreeItem>
          } else {
            return <TreeItem 
              key={prefix+key+value} 
              nodeId={prefix+key+value} 
              label={
                <div>
                  <span>
                    {`${key} : ${typeof value === 'string' ? `"${value}"` : value}`}
                  </span>
                  <span style={{fontStyle: "italic", marginLeft: '5px'}} >
                    {typeof value}
                  </span>   
                </div>
              }
            />
          }
        })
      } else {
        return null
      }
    } else if (Array.isArray(nodes)) {
    }
  }

  return (
    <TreeView
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpanded={['root']}
    defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data)}
    </TreeView>
  )
}
