import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FullScreenPlanWrapper = styled.div`
  top: 0;
  height: 100svh;
  max-height: 100svh;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center 0;
  background-image: ${(props) => `url("${props.server}/images/${props.id}_map_0.png")`};
  position: absolute;
  background-color: white;
  max-width: none;
  z-index: 50;
  width: 100% !important;
`;

function FullScreenPlan({ monumentId }) {
  const { server } = useSelector((state) => state.appSlice);

  return (
    <>
      <FullScreenPlanWrapper server={server} id={monumentId} />
    </>
  );
}

export default FullScreenPlan;
