import styled from 'styled-components';
import load from '../../load.gif';

const LoadingBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(200,200,200,0.3);
  z-index: 5000;
`

const Load = styled.img`
  margin: auto;
  width: 100px;
`

function Loading() {

return <LoadingBackground>
  <Load src={load} alt="loading"></Load>
</LoadingBackground>

}

export default Loading;