import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LppAddIcon, LppEditIcon, LppLogoutIcon, LppSortIcon } from '../components/lppIcons';


const Bottom = styled.div`
  position: fixed;
  width: 100% !important;
  bottom: 0;
  display: flex;
  z-index: 25;
`

const LPPBottomContainer = styled.div`
  width: 500px;
  max-width: 100%;
  margin: auto;
  position: relative;
  height: 65px;
  background-color: #eee;
`

const Spacer = styled.div`
  min-height: 70px;
`

function BottomContainer({monument, setSorting, isSorting, type}) {

return monument ? (
  <>
  <Spacer />
  <Bottom>
  <LPPBottomContainer>
  <Link key={`logout`} to={`/logout`}>
    <LppLogoutIcon />
  </Link>
  <Link key={`add_${monument.monument_id}`} to={`/edit/${type}/new`}>
    <LppAddIcon />
  </Link>
  {setSorting &&
  <Link onClick={setSorting} style={{color: `${isSorting ? "red" : "blue"}`}}>
    <LppSortIcon />
  </Link>}
  <Link key={`edit_${monument.monument_id}`} to={`/edit/monument/${monument.monument_id}`}>
    <LppEditIcon bottom={true} />
  </Link>

  </LPPBottomContainer>
  </Bottom>
  </>)
  : null
}

export default BottomContainer;