import { Button, TextField } from '@material-ui/core';
import React, {useState} from 'react';
import QrReader from 'react-qr-scanner';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import logo from '../../logo.png';

const HButton = styled(Button)`
  margin: 10px auto !important;
`;

const HTextField = styled(TextField)`
  margin: 10px auto !important;
`;

const Logo = styled.img`
  width: unset !important;
  height: 25vh;
  margin: 10vh auto !important;
`;

function Home() {
  const [qr, setQr ] = useState(false);
  const [ visit, setVisit ] = useState('');
  let history = useHistory();

  const handleScan = (data) => {
    if (data)
      window.location.href = data;
  }

  const handleError = (err) => {
    console.error(err)
  }

  return (
    <>
    <Logo src={logo} alt="logo" />
    <form onSubmit={() => history.push(`/lpp/${visit}`)}>
      <HTextField inputProps={{ inputMode: 'decimal' }} placeholder="N° de visite" value={visit} onChange={(v) => setVisit(v.target.value)}></HTextField>
      <HButton type="submit" variant="containedPrimary">Démarrer la visite</HButton>
    </form>
    <HButton variant="containedPrimary" onClick={() => setQr(true)}>Ou scanner le QrCode</HButton>
    {qr &&
      <QrReader
            style={{
              height: 240,
              width: 320,
            }}
            onError={handleError}
            onScan={handleScan}
            facingMode={'rear'}
            />}

    <HButton variant="containedPrimary" onClick={() => history.push(`/list/0`)}>Voir toutes les visites</HButton>
    <br /><br /><br />
    <HButton variant="containedPrimary" onClick={() => history.push(`/auth`)}>Se connecter</HButton>

    </>
  )
}

export default Home;
