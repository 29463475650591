import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
      light: green[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

export default Theme;