import React from 'react';
import './App.css';
import { HashRouter as Router, Route } from "react-router-dom";
import Home from './app/pages/home';
import Splash from './app/pages/splash';
import List from './app/pages/list';
import Edit from './app/pages/edit';
import Slider from './app/pages/slider';
import Header from './app/header/header';
import styled, { ThemeProvider } from 'styled-components';
import Theme from './app/styles/theme';
import Users from './app/admin/users';
import Auth from './app/admin/auth';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import { setToken } from './app/redux/appSlice';
import { useDispatch } from 'react-redux';


const Phone = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  & > * {
    margin: auto;
    width: 500px;
    max-width: 100%;
  }
`;

function App() {
  const [cookie] = useCookies()
  const dispatch = useDispatch();

  if (cookie) dispatch(setToken(cookie))

  return (
    <CookiesProvider>
      <ThemeProvider theme={Theme}>
        <Phone>
          <Router>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route path="/lpp/:monumentID">
              <Splash></Splash>
            </Route>
            <Route path="/list/:monumentID">
              <Header></Header>
              <List></List>
            </Route>
            <Route path="/slider/:parentID/:elementID">
              <Slider></Slider>
            </Route>
            <Route path="/edit/:type/:monumentID">
              <Header></Header>
              <Edit></Edit>
            </Route>
            <Route path="/auth">
              <Header title="Login"></Header>
              <Auth></Auth>
            </Route>
            <Route path="/lpp_users">
              <Users></Users>
            </Route>
          </Router>
        </Phone>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
