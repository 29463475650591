import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import {
  Formik, Form
} from 'formik';
import FormikTextField from '../components/FormikTextField';

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #eeeeee;
  }
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  & > td {
    padding: 0;
  }
`;

function Users() {
  const { webservices } = useSelector(state => state.appSlice);
  const [ users, setUsers ] = useState([])
  const [edit, setEdit] = useState(null)

  const init = async () => {
    const result = await axios.get(webservices+"/admin/users");
    setUsers(result.data);
  }

  const submit = async (values) => {
    values.password = values.pass;
    if (!values.user_id && !values.pass) {
      alert("Le mot de passe est obligatoire");
      return;
    }
    try {
      await axios.post(webservices+"/admin/users", values)
      setEdit(null);
      init();
    } catch (error) {
      console.log(error)
    }
    
  }

  const addAuth = async (user_id, monument_id) => {
    try {
      await axios.post(webservices+"/admin/users/authorizations", {user_id, monument_id})
      init();
    } catch (error) {
      console.log(error)
    }
  }

  const removeAuth = async (user_id, monument_id) => {
    try {
      await axios.delete(webservices+"/admin/users/authorizations", {data: {user_id, monument_id}})
      init();
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    init();
  },[])
  
  return (
  <>
  <Dialog
      maxWidth="lg"
      fullWidth
      open={edit !== null}
      onClose={() => setEdit(null)}
      scroll="body"
    >
      { edit !== null && (
          <DialogContent>
            <Formik
            initialValues={users.find(user => user.user_id === edit) || {}}
            onSubmit={(values) => {
              submit(values)
            }}
          >
            <Form>
              <FormikTextField name="name" label="Nom" />
              <FormikTextField name="mail" label="Email" />
              <FormikTextField name="pass" label="Mot de passe" />

              <Button type="submit">Valider</Button>
            </Form>
          </Formik>
          </DialogContent>
      )}
    </Dialog>
  <Table>
    <TableHead>
          <CustomTableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </CustomTableRow>
        </TableHead>
        <TableBody>
    {users.map(user => <>
      <CustomTableRow>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.mail}</TableCell>
        <TableCell><Button onClick={()=>setEdit(user.user_id)}>Edit</Button></TableCell>
      </CustomTableRow>
      <CustomTableRow><TableCell>
        {user.authorizations && user.authorizations.map(auth => 
          <li>{auth.id} - {auth.name} <Button type="button" onClick={() => removeAuth(user.user_id, auth.id)}> - </Button></li>)}
          <Formik
            initialValues={{
              monument_id: null
            }}
            onSubmit={(values) => {
              addAuth(user.user_id, values.monument_id)
            }}
          >
        <Form>
          <FormikTextField name="monument_id" /><Button type="submit"> + </Button>
        </Form>
        </Formik>
        </TableCell></CustomTableRow>
      </>
    )
    }
    </TableBody>
  </Table>
  <Button onClick={()=>setEdit(-1)}>Ajouter un utilisateur</Button>
  </>)
}

export default Users;
