import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMonument } from "../redux/appSlice";
import styled from "styled-components";
import detectBrowserLanguage from 'detect-browser-language'

const Lang = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-around;
  border: 1px solid grey;
  padding: 30px;
  cursor: pointer;
  background-color: ${props=>props.even ? 'lightgrey': 'white'};
`

const Monument = styled.div`
  height: 100svh;
  max-height: 100svh;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center 0;
  background-image: ${(props) =>
    `url("${props.server}/images/${props.id}.png")`};
  position: relative;
  h1 {
    padding-top: 1em;
    font-size: 20pt;
    font-weight: bold;
    color: white;
  }
  h2 {
    padding: 15px 0px;
    font-size: 12pt;
    font-weight: normal;
    color: white;
  }
  div {
    padding: 10px;
    height: 25%;
    width: 75%;
    bottom: 0;
  }
  div.title-box {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid black;
    border-radius: 0px 5px 5px 0px;
  }
  
`;

function Splash() {
  let { monumentID } = useParams();
  let history = useHistory();
  const { monument, server, translations } = useSelector((state) => state.appSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    let lng = detectBrowserLanguage().toString().slice(0,2).toUpperCase();
    console.log("language ", lng)
    dispatch(getMonument(monumentID, lng));
  }, [])

  useEffect(() => {
    if (monument?.monument_id) {
      setTimeout(() => {
        history.push(`/list/${monument.monument_id}`);
      }, 5000);
    }
  }, [monument]  );

  return (
    <>
      {!monument?.monument_id && translations &&
      <div style={{marginTop: "50px"}}>
         {translations.map((translation, idx) => (
          <Lang onClick={()=>dispatch(getMonument(translation.relative_id))} even={idx%2}>
            <img src={`/${translation.language_id}.png`} width="33vw" alt={`/${translation.language_id}`} />
            <div>
              {translation.name}
            </div>
          </Lang>
         ))}
      </div>}
      
      {monument && monument.hasOwnProperty("monument_id") && (
        <>
          <Monument key={monumentID} server={server} id={monumentID}>
            <div className="title-box">
              <h1>{monument.name}</h1>
              <h2>{monument.description}</h2>
            </div>
          </Monument>
        </>
      )}
    </>
  );
}

export default Splash;
