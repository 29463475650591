import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';

const FormikSwitch = (props) => {
  const [field, meta, helpers] = useField(props);
  const defaultValue = props.defaultValue || false;

  const {
    name, disabled, label, style, ...other
  } = props;
  return (
    <FormControlLabel
      {...other}
      style={{ marginTop: '8px', ...style }}
      control={
        <Switch disabled={disabled} checked={field.value || defaultValue} onChange={(event) => helpers.setValue(event.target.checked)} value={name} />
    }
      label={label}
    />
  );
};

export default FormikSwitch;
