import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Dialog, DialogContent, DialogTitle, List, ListItem, TextField } from '@material-ui/core';
import '../styles/carousel.css';
import Header from '../slide-header/header';
import {
  recordSession, move, searchDefinitions, addDefinition, remove
} from '../redux/appSlice';
import ReactGA from 'react-ga';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Autocomplete } from '@material-ui/lab';
import { throttle} from 'lodash';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { LppEditIcon, LppTrashIcon } from '../components/lppIcons';

const HeaderImg = styled.div`
  background-position: center;
  height: 40vh;
  max-width: 500px;
  margin: auto;
  position: relative;  
  background-image: ${(props) => `url("${props.server}/images/${props.id}_0.png")`};
`
const MainContent = styled.div`
  padding: 0 20px 10px 20px;
`
const Location = styled.div`
  color: #777;
  font-style: italic;
  font-size: 12px !important;
  margin: 5px;
`

const Definition = styled(ListItem) `
  margin: 10px 0 !important;  &:nth-child(2n+1) {
    background-color: #eee !important ;
  }
  &:nth-child(2n) {
    background-color: #fff !important ;
  }
`

const DialogLittleTitle = styled(DialogTitle) `
  padding: 5px 5px 0 0 !important; 
  display: flex;
  flex-direction: row-reverse;
`

function Slide({element, display, isSorting}) {
  const { monument, server } = useSelector(state => state.appSlice);
  const [ definitions, setDefinitions ] = useState([])
  const [ play, setPlay ] = useState()
  const [ query, setQuery] = useState('')
  const dispatch = useDispatch()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex)
    dispatch(move(element, oldIndex, newIndex))
  }

  const SortableItem = SortableElement(({value}) => <div>{value}</div>);

  const SortableList = SortableContainer(({ items }) => (
    <ul style={{ listStyleType: "none", padding: "0" }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </ul>
  ));

  useEffect(() => {
    if (display) {
      ReactGA.pageview("/"+monument.monument_id + "/" + element.monument_id + "-" + element.name);
      dispatch(recordSession(element.monument_id, true))
    }
  }, [display])


  const throtlled = throttle(async (query) => {
    console.log(query);
    // await sleep(5000)
    const data = await searchDefinitions(query)

    setDefinitions(data);

  }, 500);

  useEffect(() => {
    (async () => {
      if (query.length > 2) {
        throtlled(query);
      } else {
        setDefinitions([]);
      }
    })();
  }, [query]);

  const onAutoCompleteChange = (event, value, reason) => {
    if (reason === 'select-option') {
      console.log('value, options', value);
      
      dispatch(addDefinition(element.monument_id, value.monument_id));
      //window.location.reload();

      setDefinitions([]);
      setQuery('')
    }
  };

  let items;
  if (element && element.definitions) {
    items = element.definitions.filter(e => !e.is_deleted).map( (definition, position) => (
      <Definition onClick={() => { if (definition.hasmp3) {setPlay(definition.monument_id)}} }>
        <p>{definition.hasmp3 && <VolumeUpIcon style={{verticalAlign: "-6px"}} />}<b>{definition.name}{' : '}</b>{definition.description}</p>
        {monument.isAuth && <>
          <LppTrashIcon bottom={false} onClick={() => dispatch(remove(element, definition.monument_id, position))} />
          <Link key={`edit_${definition.monument_id}`} to={`/edit/detail/${definition.monument_id}`}>
            <LppEditIcon />
          </Link>
        </>}
      </Definition>)
    )
    if (monument.isAuth) {
      items = items.concat(
        <Definition>
          <Autocomplete 
            style={{width: "100%"}}
            autoComplete={true} 
            onChange={onAutoCompleteChange}
            options={definitions} 
            onKeyUp={(e)=>setQuery(e.target.value)} 
            renderInput={
              (params) => <TextField {...params} label="Rechercher une définition" variant="outlined" />
            }
            renderOption={(definition) => <div><b>{definition.name} : </b>{definition.description}</div>} 
            getOptionLabel={(definition) => definition.name}
            filterOptions={x => x}
            noOptionsText={query.length > 2 ? "Pas de résultat" : "Tapez les premières lettres"}
            />
        </Definition>
      )
    }
  }

  const handleClose = () => {
    setPlay(null);
  }
  
  return (
    <div>
      <Header monument={element}></Header>
      <HeaderImg server={server} id={element.monument_id} />

        <Dialog open={play} onClose={handleClose} >
          <DialogLittleTitle><CloseIcon onClick={handleClose} /></DialogLittleTitle>
          <DialogContent>
        <audio controls="controls" className="audioPlayer" preload="undefined" autoPlay="true">             
          <source id="mp3" type="audio/mp3" src={`${server}/sounds/${play}.mp3`} />  
          <source id="ogg" type="audio/ogg" />          
        </audio>
        </DialogContent>
        </Dialog>
      

      <MainContent>
        {element.position && <Location><LocationOnIcon />{element.position}</Location>}
        {element.hasmp3 && <div>
          <audio controls="controls" className="audioPlayer" preload="undefined">             
              <source id="mp3" type="audio/mp3" src={`${server}/sounds/${element.monument_id}.mp3`} />  
              <source id="ogg" type="audio/ogg" />          
          </audio>
        </div>}
        <List component="div">
          {isSorting && display ?
        <SortableList
          lockAxis="y"
          helperClass="field_sorting"
          items={items}
          onSortEnd={onSortEnd}
          shouldCancelStart={e => e.target.matches('.nodrag, input, select, option, button')}
        /> :
        items}
        </List>

      </MainContent>

    </div>
  )
}

export default Slide;
