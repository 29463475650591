import React, { useMemo, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, FormHelperText } from "@material-ui/core";
import { useField } from "formik";
import styled from "styled-components";

const NoClickListSubheader = styled(ListSubheader)`
  pointer-events: none;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 8px;
`;

const FormikSelect = ({
  options,
  label,
  defaultValue = "",
  variant,
  className,
  displayEmpty = false,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <StyledFormControl
      className={className}
      variant={variant}
      error={meta.touched && meta.error != null}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={field.name} onChange={field.onChange} value={field.value || defaultValue} label={label}>
        {options.map((option) => {
          if (option.items) {
            return (
              option.items.length > 0 && [
                <NoClickListSubheader>{option.label}</NoClickListSubheader>,
                option.items.map((item) => (
                  <MenuItem key={item.value} value={item.value} disabled={item.disabled || false}>
                    {item.label}
                  </MenuItem>
                )),
              ]
            );
          } else {
            return (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
                {option.label}
              </MenuItem>
            );
          }
        })}
      </Select>
      {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default FormikSelect;
