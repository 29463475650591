import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slide from './slide';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMonument, setDetail
} from '../redux/appSlice';
import BottomContainer from '../components/BottomContainer';
import { useHistory } from "react-router-dom";

function Slider() {
  const { monument, server } = useSelector(state => state.appSlice);
  const dispatch = useDispatch();
  const { parentID, elementID } = useParams();
  const [isReorder, setReorder] = useState(false);
  const [index, setIndex] = useState(-1);

  let history = useHistory();

  let monumentId = monument.monument_id ? monument.monument_id : parentID;

  const setSorting = () => {
    setReorder(!isReorder);
  }

  useEffect(() => {
    if (monumentId && !monument.monument_id)
      dispatch(getMonument(monumentId))
  }, [parentID]);

  const getThumbs = () => {
    if (monument.children) {
      if (elementID && index == -1) {
        const idx = monument.children.findIndex(child => child.monument_id == elementID);
        setIndex(idx)
      }
      return monument.children.map(child => (
        <img key={child.monument_id} src={`${server}/images/${child.monument_id}_0.png`} alt={child.name} />
      ))
    }
  }

  const onChange = (idx) => {
    for (let elt of document.getElementsByClassName("audioPlayer")) elt.pause();
    history.push(`/slider/${parentID}/${monument.children[idx].monument_id}`)
    dispatch(setDetail(monument.children[idx].monument_id));
    setIndex(idx);
  }

  return (
    <>
      <Carousel renderThumbs={getThumbs} onChange={onChange} style={{ position: "relative" }} selectedItem={index} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
        {monument.children && monument.children.map((child, idx) => (
          <Slide key={child.monument_id} element={child} display={index === idx} isSorting={isReorder}></Slide>
        ))}
      </Carousel>
      {monument.isAuth && monument.children &&
        <BottomContainer monument={monument.children[index]} setSorting={setSorting} isSorting={isReorder} type='detail' />
      }
    </>)


}

export default Slider;
