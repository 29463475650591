import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddIcon from "@material-ui/icons/Add";
import ReorderIcon from "@material-ui/icons/Reorder";
import TrashIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import MapIcon from "@material-ui/icons/Map";

const LppSortIcon = styled(ReorderIcon)`
  position: absolute;
  right: 60px;
  bottom: 10px;
  font-size: 48px !important;
`;

const LppEditIcon = styled(EditIcon)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: ${(props) => (props.bottom ? "48px" : "30px")} !important;
`;

const LppAddIcon = styled(AddIcon)`
  position: absolute;
  right: 120px;
  bottom: 10px;
  font-size: 48px !important;
`;

const LppLogoutIcon = styled(ExitToAppIcon)`
  position: absolute;
  right: 170px;
  bottom: 10px;
  font-size: 48px !important;
`;

const LppMapIcon = styled(MapIcon)`
  font-size: 48px !important;
`;

const LppTrashIcon = styled(TrashIcon)`
  position: absolute;
  right: 60px;
  bottom: 10px;
  font-size: ${(props) => (props.bottom ? "48px" : "30px")} !important;
  color: ${(props) => (props.bottom ? "black" : "red")} !important;
`;

const LppRestoreFromTrashIcon = styled(RestoreFromTrashIcon)`
  position: absolute;
  right: 60px;
  bottom: 10px;
  font-size: ${(props) => (props.bottom ? "48px" : "30px")} !important;
  color: ${(props) => (props.bottom ? "black" : "red")} !important;
`;

export { LppEditIcon, LppLogoutIcon, LppMapIcon, LppAddIcon, LppSortIcon, LppTrashIcon, LppRestoreFromTrashIcon };
