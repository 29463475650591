import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'

function LPPMap({monument}) {
    const children = monument.children.filter(child => child.latitude);
    const latitude = children.reduce((prev, curr) => prev+curr.latitude, 0)/children.length;
    const longitude = children.reduce((prev, curr) => prev+curr.longitude, 0)/children.length;
    return (
<MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false} style={{height: "calc(100vh - 115px)"}}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  {children.map(child => 
  <Marker position={[child.latitude, child.longitude]} >
    <Popup>
        <div
            style={{cursor:"pointer"}} 
            onClick={()=>window.location.href = `https://appli.lespierresparlent.com/#/lpp/${child.monument_id}`}>
            <b>{child.name}</b><br />
            {child.description}
        </div> 
    </Popup>
  </Marker>)}
</MapContainer>)

}

export default LPPMap