import React, { useEffect } from 'react';
import logo from '../../logo.png';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

const AppTitle = styled.h1`
  color: #011638;
  font-size: 20px;
  margin: 0;
`;

const AppDescription = styled.p`
  color: #011638;
  margin: 0;
`;

const Logo = styled.img`
  width: unset !important;
  height: 75%;
  margin: 1% 2%;
`;

function Header({ title }) {
  const { monument: reduxMonument, parentMonument } = useSelector(state => state.appSlice);
  let history = useHistory();

  const monument = reduxMonument?.monument_id === 'new' ? parentMonument : reduxMonument

  return (
    <div style={{ height: "50px", display: "flex" }}>
      <Logo src={logo} alt="logo" onClick={() => history.push(`/`)} />
      <div>
        <AppTitle>{title ? title : monument ? monument.name : "Les Pierres Parlent"}</AppTitle>
        <AppDescription>{monument ? monument.description?.split(' ').slice(0, 10).join(' ') : "Vous allez écouter ce que vous allez voir"}</AppDescription>
      </div>
    </div>
  )
}

export default Header;
